<template>
  <div class="IncomeAndExpenditure-wrapper">
    <div class="income-table">
      <div class="table-title">总收入：{{this.tableData1[4].value}}</div>
      <div class="table-body">
        <el-table
          :data="tableData1"
          border
          style="width: 361px"
          :show-header="false"
          :cell-style="cellStyle"
        >
          <el-table-column prop="item" width="180" align="center"></el-table-column>
          <el-table-column prop="value" width="180" align="center"></el-table-column>
        </el-table>
      </div>
    </div>
    <div class="expenditure-table">
      <div class="table-title">总支出：{{this.tableData2[3].value}}</div>
      <div class="table-body">
        <el-table
          :data="tableData2"
          border
          style="width: 361px"
          :show-header="false"
          :cell-style="cellStyle"
        >
          <el-table-column prop="item" width="180" align="center"></el-table-column>
          <el-table-column prop="value" width="180" align="center"></el-table-column>
        </el-table>
      </div>
    </div>

    <div class="avtivity-table">
      <div class="table-title">活动费用：{{TotalActivityFee}}</div>
      <div class="table-body" style="margin-bottom:100px">
        <el-table
          :data="tableData3"
          border
          style="width:901px"
          :cell-style="cellStyle"
          :header-row-style="headerRowStyle"
        >
          <el-table-column prop="title" width="180" label="收费项目" align="center"></el-table-column>
          <el-table-column prop="computedWay" width="180" label="计算方式" align="center"></el-table-column>
          <el-table-column prop="indexValue" width="180" label="对应指标数值" align="center">
            <template slot-scope="scope">
              {{scope.row.indexValue}}
              <el-button
                @click="handleEdit(0,scope.row)"
                v-if="scope.$index===6||scope.$index===1"
                type="text"
                size="small"
              >设置</el-button>
            </template>
          </el-table-column>
          <el-table-column width="180" label="系数" align="center">
            <template slot-scope="scope">
              <div v-if="scope.row.title!='直播红包'&&scope.row.title!='合计'">
                {{scope.row.coefficient}}
                <el-button @click="handleEdit(1,scope.row)" type="text" size="small">设置</el-button>
              </div>
              <div v-else>/</div>
            </template>
          </el-table-column>
          <el-table-column prop="total" width="180" label="总计" align="center">
            <template slot-scope="scope">
              <div
                v-if="scope.row.title!='直播红包'&&scope.row.title!='合计'"
              >{{Number(scope.row.coefficient).mul(scope.row.indexValue)}}</div>
              <div v-if="scope.row.title=='直播红包'">{{scope.row.indexValue}}</div>
              <div v-if="scope.row.title=='合计'">{{TotalActivityFee}}</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <el-dialog :visible.sync="editDialog" width="15%" :show-close="false">
      <el-input
        v-model="editValue"
        placeholder
        maxlength="7"
        show-word-limit
        v-if="limitnum=='long'"
      ></el-input>
      <el-input v-model="editValue" placeholder maxlength="4" show-word-limit v-else></el-input>

      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialog = false">取消</el-button>
        <el-button type="primary" @click="updateFinanceConfig">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import MarketingAjax from "@/utils/https/modules/Marketing.request.js";
import ActivityAjax from "@/utils/https/modules/Activity.request.js";
import FinanceAjax from "@/utils/https/modules/Finance.request.js";
export default {
  name: "IncomeAndExpenditure", //收支情况
  props: {
    activityID: String,
  },
  data() {
    return {
      TotalActivityFee: 0,
      editDialog: false,
      editValue: 0,
      tableData1: [
        {
          item: "导购员充值",
          value: 0,
        },
        {
          item: "品牌充值",
          value: 0,
        },
        {
          item: "财务充值",
          value: 0,
        },
        {
          item: "订单交易金额",
          value: 0,
        },
        {
          item: "合计",
          value: 0,
        },
      ],
      tableData2: [
        {
          item: "预热页推广奖励",
          value: 0,
        },
        {
          item: "导购员KPI单项奖励金",
          value: 0,
        },
        {
          item: "导购员KPI奖励豆奖励",
          value: 0,
        },
        {
          item: "合计",
          value: 0,
        },
      ],
      tableData3: [
        {
          title: "流量费",
          computedWay: "浏览量*系数",
          indexValue: "0",
          coefficient: "0",
          total: "0",
        },
        {
          title: "短信费",
          computedWay: "短信量*系数",
          indexValue: "0",
          coefficient: "0",
          total: "0",
        },
        {
          title: "秒杀订单提现手续费",
          computedWay: "秒杀订单总额 * 系数",
          indexValue: "0",
          coefficient: "0",
          total: "0",
        },
        {
          title: "直播订单提现手续费",
          computedWay: "直播订单总额 * 系数",
          indexValue: "0",
          coefficient: "0",
          total: "0",
        },
        {
          title: "售卡扣款",
          computedWay: "卡券购买量 * 系数",
          indexValue: "0",
          coefficient: "0",
          total: "0",
        },
        {
          title: "直播间提单",
          computedWay: "直播订单数  * 系数",
          indexValue: "0",
          coefficient: "0",
          total: "0",
        },
        {
          title: "直播红包",
          computedWay: "直播间发放红包金额（手动输入）",
          indexValue: "0",
          coefficient: "0",
          total: "0",
        },
        {
          title: "合计",
          computedWay: "/",
          indexValue: "/",
          coefficient: "/",
          total: "0",
        },
      ],
      BonusrecordQueryShareSumDetail: {},
      edititem: {},
      edittype: 1,
      editId: 0,
      tabletotal1: 0,
      tabletotal2: 0,
      limitnum: "long",
    };
  },
  methods: {
    handleEdit(type, row) {
      console.log(row);
      if (type == 1) {
        this.limitnum = "short";
        this.editValue = row.coefficient;
      } else {
        this.limitnum = "long";
        this.editValue = row.indexValue;
      }
      this.edittype = type;
      this.edititem = row;
      this.editDialog = true;
    },
    cellStyle({ columnIndex }) {
      if (columnIndex == 0) {
        return "background:rgb(242,242,242);";
      }
    },
    headerRowStyle() {
      return { background: "red" };
    },
    // 总支出--预热页推广奖励
    async getDetail() {
      try {
        this.tableData1[0].value = (
          await FinanceAjax.getActacountQueryKpiUserSum(this.activityID)
        ).data;
        this.tableData1[1].value = (
          await FinanceAjax.getChargeBrandChargeSum(this.activityID)
        ).data;
        this.tableData1[2].value = (
          await FinanceAjax.getChargeFinanceChargeSum(this.activityID)
        ).data;
        this.tableData1[3].value = (
          await FinanceAjax.getOrderQuerySum(this.activityID)
        ).data;
        var tableTotal1 = Number(this.tableData1[0].value)
          .add(this.tableData1[1].value)
          .add(this.tableData1[2].value)
          .add(this.tableData1[3].value);

        this.tableData1[4].value = tableTotal1.toFixed(2);

        this.tableData2[0].value = (
          await FinanceAjax.getBonusrecordQueryShareSum(this.activityID)
        ).data;
        this.tableData2[1].value = (
          await FinanceAjax.getActacountQuerySingleSum(this.activityID)
        ).data;
        this.tableData2[2].value = (
          await FinanceAjax.getActacountQueryBeanSum(this.activityID)
        ).data;
        this.tableData2[3].value = Number(this.tableData2[0].value)
          .add(this.tableData2[1].value)
          .add(this.tableData2[2].value);

        this.tableData3[0].indexValue = (
          await FinanceAjax.getUsertagQueryCount(this.activityID)
        ).data;
        this.tableData3[2].indexValue = (
          await FinanceAjax.getOrderQuerySeckillSum(this.activityID)
        ).data;
        this.tableData3[3].indexValue = (
          await FinanceAjax.getOrderQueryLiveSum(this.activityID)
        ).data;
        this.tableData3[4].indexValue = (
          await FinanceAjax.getCardrecordQueryCount(this.activityID)
        ).data;
        this.tableData3[5].indexValue = (
          await FinanceAjax.getOrderQueryLiveCount(this.activityID)
        ).data;

        var detail = (await FinanceAjax.getConfigQueryByActId(this.activityID))
          .data;
        this.tableData3[0].coefficient = detail.view;
        this.tableData3[1].coefficient = detail.message;
        this.tableData3[1].indexValue = detail.messageCount;
        this.tableData3[2].coefficient = detail.seckillsOrderWithdrawalFee;
        this.tableData3[3].coefficient = detail.liveOrderWithdrawalFee;
        this.tableData3[4].coefficient = detail.card;
        this.tableData3[5].coefficient = detail.liveOrder;
        this.tableData3[6].coefficient = detail.liveRedPacket;
        this.tableData3[6].indexValue = detail.liveRedPacketCount;
        this.editId = detail.id;

        var total = 0;
        this.tableData3.forEach((item) => {
          if (item.title != "直播红包" && item.title != "合计") {
            item.total = Number(item.coefficient).mul(Number(item.indexValue));
            console.log(item.total, item.title);
            var num = Number(item.total);
            total = Number(total).add(num);
          } else if (item.title == "直播红包") {
            item.total = item.indexValue;
            console.log(item.total, item.title);
            var num = Number(item.total);
            total = Number(total).add(num);
          }
        });
        console.log(total);
        this.TotalActivityFee = total.toFixed(2);
      } catch (error) {
        this.$message.error(error);
      }
    },
    async search() {
      var detail = (await FinanceAjax.getConfigQueryByActId(this.activityID))
        .data;
      this.tableData3[0].coefficient = detail.view;
      this.tableData3[1].coefficient = detail.message;
      this.tableData3[1].indexValue = detail.messageCount;
      this.tableData3[2].coefficient = detail.seckillsOrderWithdrawalFee;
      this.tableData3[3].coefficient = detail.liveOrderWithdrawalFee;
      this.tableData3[4].coefficient = detail.card;
      this.tableData3[5].coefficient = detail.liveOrder;
      this.tableData3[6].coefficient = detail.liveRedPacket;
      this.tableData3[6].indexValue = detail.liveRedPacketCount;
      this.editId = detail.id;
      var total = 0;
      this.tableData3.forEach((item) => {
        if (item.title != "直播红包" && item.title != "合计") {
          item.total = Number(item.coefficient).mul(Number(item.indexValue));
          console.log(item.total);
          var num = Number(item.total);
          total = Number(total).add(num);
        } else if (item.title == "直播红包") {
          item.total = item.indexValue;
          var num = Number(item.total);
          total = Number(total).add(num);
        }
      });
      this.TotalActivityFee = total.toFixed(2);
    },
    // 字符串判空
    isNullOrEmpty(str) {
      if (str === "" || str == null) {
        return true; // 返回正确的处理结果
      }
      return false; // 分会错误的处理结果，终止处理
    },
    // 活动费用系数及红包、短信更新
    async updateFinanceConfig() {
      try {
        if (this.isNullOrEmpty(this.editValue)) {
          this.$message.warning("输入值不能为空");
          return;
        }
        if (/^\d+(\.\d{1,2})?$/.test(this.editValue) == false) {
          this.$message.warning("请输入正确输入值，最多两位小数");
          return;
        }

        var act = {
          actId: this.activityID,
          id: this.editId,
        };
        if (this.edititem.title == "流量费") {
          act.view = this.editValue;
        } else if (this.edititem.title == "短信费") {
          if (this.edittype == 1) {
            act.message = this.editValue;
          } else {
            act.messageCount = this.editValue;

            act.view = this.tableData3[0].coefficient;
            act.message = this.tableData3[1].coefficient;
            act.seckillsOrderWithdrawalFee = this.tableData3[2].coefficient;
            act.liveOrderWithdrawalFee = this.tableData3[3].coefficient;
            act.card = this.tableData3[4].coefficient;
            act.liveOrder = this.tableData3[5].coefficient;
            act.liveRedPacketCount = this.tableData3[6].indexValue;
          }
        } else if (this.edititem.title == "秒杀订单提现手续费") {
          act.seckillsOrderWithdrawalFee = this.editValue;
        } else if (this.edititem.title == "直播订单提现手续费") {
          act.liveOrderWithdrawalFee = this.editValue;
        } else if (this.edititem.title == "售卡扣款") {
          act.card = this.editValue;
        } else if (this.edititem.title == "直播间提单") {
          act.liveOrder = this.editValue;
        } else if (this.edititem.title == "直播红包") {
          act.liveRedPacketCount = this.editValue;

          act.view = this.tableData3[0].coefficient;
          act.message = this.tableData3[1].coefficient;
          act.messageCount = this.tableData3[1].indexValue;
          act.seckillsOrderWithdrawalFee = this.tableData3[2].coefficient;
          act.liveOrderWithdrawalFee = this.tableData3[3].coefficient;
          act.card = this.tableData3[4].coefficient;
          act.liveOrder = this.tableData3[5].coefficient;
        }

        console.log(act);
        await FinanceAjax.updateFinanceConfig(act);
        this.$message.success("设置成功");
        this.editDialog = false;
        await this.search();
      } catch (error) {
        this.$message.error(error);
      }
    },
  },
  created() {
    this.getDetail();
  },
};
</script>

<style lang="scss" scoped>
.IncomeAndExpenditure-wrapper {
  .table-title {
    font-weight: 600;
    font-size: 18px;
    margin: 20px 0;
  }
  .table-body {
  }
}
.info-row {
  background: blue;
}
</style>
